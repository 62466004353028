<template>
  <div class="card-notification__header">
    <div class="card-notification__text">{{ formatNotificationDate }}</div>

    <div v-if="item.state === 1">
      <span class="card-notification__circle" />

      <span class="card-notification__text">
        {{ getContent(currentLocaleData, defaultLocaleData, 'new') }}
      </span>
    </div>
  </div>

  <div v-if="currentType?.showImg" class="card-notification__img">
    <atomic-image :src="item.params.img" />
  </div>

  <div class="card-notification__content">
    <div class="card-notification__row">
      <atomic-image v-if="showImage" class="input-deposit__logo" :src="currencyImageSrc" />

      <atomic-icon v-else class="card-notification__icon" :class="`card-notification__icon--${iconId}`" :id="iconId" />

      <div class="card-notification__title">{{ title }}</div>
    </div>

    <div class="card-notification__body">
      {{ body }}

      <span v-if="showLinkDepositError" class="card-notification__link" @click="openDepositModal">
        {{ getContent(currentLocaleData, defaultLocaleData, 'tryAgain') }}
      </span>
    </div>
  </div>

  <button-base v-if="currentType?.showBtn" class="card-notification__btn" type="primary" size="sm" @click="actionBtn">
    {{ currentContent.btn }}
  </button-base>
</template>

<script setup lang="ts">
  import { notificationTypes, NotificationCode } from '@skeleton/consts/user-notification';
  import { DEFAULT_IMG } from '@skeleton/consts/staticPath';

  import type { IUserNotification } from '@skeleton/core/types';
  import type { INotification } from '~/types';

  const props = defineProps<{
    item: IUserNotification;
    currentLocaleData: Maybe<INotification>;
    defaultLocaleData: Maybe<INotification>;
  }>();

  const emits = defineEmits<{
    (event: 'closePanel'): void;
  }>();

  const { getContent, localizePath } = useProjectMethods();
  const globalStore = useGlobalStore();
  const { currentLocale } = storeToRefs(globalStore);
  const { openWalletModal } = useTransactionStore();

  const { iconUrl } = useDynamicIcon('currency');

  const router = useRouter();

  const dayjs = useDayjs();

  dayjs.locale(currentLocale?.value.code);

  const createAtNotification = computed(() => {
    const { createdAt } = props.item;

    const notificationDate = dayjs.utc(createdAt).local();
    const timezoneOffset = dayjs().utcOffset();

    const adjustedDate = notificationDate.subtract(timezoneOffset, 'minute');

    return adjustedDate;
  });

  const formatNotificationDate = computed(() => {
    const now = dayjs.utc();
    let notificationDate = createAtNotification.value;

    if (notificationDate.isAfter(now)) {
      notificationDate = now.subtract(1, 'minute');
    }

    if (now.isSame(notificationDate, 'day')) {
      return notificationDate.from(now);
    }

    return notificationDate.format('MM.DD.YY');
  });

  const currentType = computed(() => {
    const { scope, code } = props.item;
    return notificationTypes?.[scope]?.[code];
  });

  const currentContent = computed(() => {
    return getContent(props.currentLocaleData, props.defaultLocaleData, currentType.value?.content);
  });

  const title = computed(() => {
    if (!currentContent.value?.title) return '';
    return replacePlaceholders(currentContent.value.title, props.item.params ?? {}, {});
  });

  const body = computed(() => {
    if (!currentContent.value?.body) return '';

    const params = { ...props.item.params };

    if (params.date) {
      const formattedDate = dayjs(params.date).utc().format('MM.DD.YYYY');
      params.date = formattedDate;
    }

    return replacePlaceholders(currentContent.value.body, params, {});
  });

  const showLinkDepositError = computed(() => {
    const { code } = props.item;
    return code === NotificationCode.DEPOSIT_ERROR;
  });

  const showImage = computed(() => {
    const { code } = props.item;
    return [NotificationCode.DEPOSIT_SUCCESS, NotificationCode.WITHDRAW_SUCCESS].includes(code);
  });

  const currencyImageSrc = computed(() => {
    const { params } = props.item;
    const { currency } = params;
    const imageSrc = `${iconUrl.value}/${currency}.svg`;
    const exists = checkImageExists(imageSrc);
    return exists ? imageSrc : DEFAULT_IMG;
  });

  const iconId = computed(() => {
    const { code } = props.item;

    switch (code) {
      case NotificationCode.BET_ACCEPTED:
      case NotificationCode.COMBO_BET_ACCEPTED:
      case NotificationCode.BET_WON:
      case NotificationCode.BET_RESULT_AVAILABLE:
        return 'note';
      case NotificationCode.BET_LIVE_UPDATE:
        return 'live';
      case NotificationCode.BET_REJECTED:
      case NotificationCode.DEPOSIT_ERROR:
      case NotificationCode.WITHDRAW_ERROR:
      case NotificationCode.ACCOUNT_BLOCKED:
        return 'info';
      case NotificationCode.WITHDRAW_PROCESSING:
        return 'clock';
    }
  });

  const checkImageExists = (src: string) => {
    return !src?.includes('undefined');
  };

  const replacePlaceholders = (
    text: string | undefined,
    params: Record<string, string | undefined>,
    extraReplacements: Record<string, string> = {}
  ): string => {
    if (!text) return '';

    const allReplacements = { ...params, ...extraReplacements };

    return Object.entries(allReplacements).reduce((result, [key, value]) => {
      return result.replace(new RegExp(`\\{${key}\\}`, 'g'), value ?? '');
    }, text);
  };

  const openDepositModal = () => {
    openWalletModal('deposit');
    emits('closePanel');
  };

  const actionBtn = () => {
    if (currentType.value.showBtn) {
      handleNotificationAction();
      emits('closePanel');
    }
  };

  const handleNotificationAction = () => {
    const { code, params } = props.item;
    const { eventId } = params;

    switch (code) {
      case NotificationCode.BET_ACCEPTED:
      case NotificationCode.BET_LIVE_UPDATE:
        router.push(`/sport#/event/${eventId}`);
        break;
      case NotificationCode.BET_WON:
        router.push(`/sport#/betHistory`);
        break;
      case NotificationCode.BET_RESULT_AVAILABLE:
        router.push(`/sport#/betHistory`);
        break;
      case NotificationCode.ACCOUNT_BLOCKED:
        router.push({
          path: localizePath('/support'),
          query: {},
        });
        break;
      case NotificationCode.WELCOME_SPORT_BONUS:
        router.push({
          path: localizePath('/bonuses'),
          query: {},
        });
        break;
    }
  };
</script>

<style src="~/assets/styles/components/card/notification.scss" lang="scss" />
