<template>
  <div :class="['list-languages', { 'list-languages_empty': !convertedList.length && !isMobile }]">
    <list-simple
      v-if="convertedList.length"
      :list="convertedList"
      :value="selectedLanguage"
      :searchValue="''"
      @selected="selectLanguage"
    />
    <atomic-empty
      v-else
      variant="not-found"
      :title="getContent(layoutData, defaultLocaleLayoutData, 'header.search.emptyLabel')"
      :subTitle="getContent(layoutData, defaultLocaleLayoutData, 'header.search.emptyText')"
    />
  </div>
</template>

<script lang="ts" setup>
  import type { ILocale } from '@skeleton/core/types';

  const emit = defineEmits(['changed']);

  const props = defineProps<{
    searchValue: string;
  }>();

  const route = useRoute();
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const { getContent } = useProjectMethods();
  const { changeProfileData } = useCoreProfileApi();
  const { isLoggedIn, isTelegramUser } = storeToRefs(useProfileStore());
  const globalStore = useGlobalStore();
  const { locales, currentLocale, layoutData, defaultLocaleLayoutData } = storeToRefs(globalStore);
  const { isMobile } = storeToRefs(useDeviceStore());

  const isProcess = ref(false);
  const selectedLanguage = ref('');

  const linkToLocale = (locale: ILocale): string => {
    const routerLocale: any = route.params.locale;

    const [path, hash] = route.fullPath.split('#');

    if (locale.isDefault) {
      const newPath = routerLocale ? path.replace(`/${routerLocale}`, '') : path;
      return hash ? `${newPath || '/'}#${hash}` : newPath || '/';
    }

    if (routerLocale) {
      const newPath = path.replace(routerLocale, locale.code.toLowerCase());
      return hash ? `${newPath}#${hash}` : newPath;
    }

    const newPath = `/${locale.code.toLowerCase()}${path === '/' ? '' : path}`;
    return hash ? `${newPath}#${hash}` : newPath;
  };

  const changeLanguage = async (locale: ILocale): Promise<void> => {
    if (currentLocale.value?.code === locale.code || isProcess.value) return;
    isProcess.value = true;

    globalStore.showPreloader();

    const { setToStorage } = useBrowserStorage(true);
    setToStorage('user-language', locale.code.toLowerCase(), { maxAge: 60 * 60 * 24 * 365 * 10 });

    if (isLoggedIn.value) {
      try {
        await changeProfileData({ locale: locale.code });
      } catch (e) {
        console.log('Error: set user lang', e);
      }
    }

    if (isTelegramUser.value) {
      window.location.reload();
    } else {
      window.location.href = linkToLocale(locale);
    }
  };

  const optionsList = computed(() => {
    if (!locales.value?.length) return [];

    const searchValue = props.searchValue?.toLowerCase();

    if (!searchValue) return locales.value;

    return locales.value.filter(
      ({ code, name }: ILocale) => code.toLowerCase().includes(searchValue) || name.toLowerCase().includes(searchValue)
    );
  });

  const getLanguageLocaleCode = (code: string | undefined) => {
    return code ? ` (${code.toUpperCase()})` : '';
  };

  const convertedList = computed(() => {
    return optionsList.value.map(item => {
      return {
        id: item.code,
        name: (item.nativeName || item.name) + getLanguageLocaleCode(item?.code),
        icon: `${gamehubCdn}/locales/${item.code.toLowerCase()}.svg`,
      };
    });
  });

  const selectLanguage = async (code: string) => {
    const locale = locales.value.find(item => item.code === code);
    if (locale) {
      await changeLanguage(locale);
      emit('changed');
    }
  };

  watch(
    currentLocale,
    () => {
      selectedLanguage.value = currentLocale.value?.code || '';
    },
    { immediate: true }
  );
</script>

<style src="~/assets/styles/components/list/languages.scss" lang="scss" />
